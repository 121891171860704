import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 755.000000 406.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,406.000000) scale(0.100000,-0.100000)">
 

<path d="M3505 3717 c-54 -58 -900 -890 -1045 -1027 -91 -85 -235 -215 -320
-289 -85 -74 -180 -156 -210 -182 -208 -182 -547 -425 -775 -556 -240 -138
-520 -236 -712 -250 l-93 -6 0 -599 c0 -340 4 -598 9 -598 5 0 57 55 117 123
60 67 156 174 214 237 58 63 181 198 272 300 224 249 315 346 320 340 3 -3 60
-85 129 -183 68 -98 150 -215 182 -260 32 -45 66 -93 75 -108 9 -14 87 -123
171 -242 l155 -217 333 0 c200 0 333 4 333 9 0 9 -22 42 -145 221 -98 141
-173 250 -241 350 -81 119 -206 301 -336 490 -56 80 -138 199 -183 266 l-83
121 49 57 c56 66 279 315 323 361 16 16 94 104 175 194 80 91 200 223 266 295
66 72 172 190 235 261 63 72 162 182 220 245 58 63 177 196 265 295 88 99 162
182 165 185 15 14 148 167 154 178 11 18 3 14 -19 -11z"/>
<path d="M352 2133 l3 -568 66 2 c118 4 316 58 452 124 l47 23 0 493 0 493
-285 0 -285 0 2 -567z"/>
<path d="M2980 1450 l0 -1250 818 2 817 3 3 248 2 247 -540 0 -540 0 0 1000 0
1000 -280 0 -280 0 0 -1250z"/>
<path d="M4952 1453 l3 -1248 280 0 280 0 3 357 2 356 368 4 c355 5 370 6 462
30 288 78 465 216 574 449 54 114 76 215 83 370 7 157 -6 268 -45 379 -85 247
-257 408 -532 495 -157 49 -241 55 -888 55 l-592 0 2 -1247z m1190 763 c141
-26 242 -98 297 -215 24 -51 26 -66 26 -186 0 -126 -1 -132 -33 -199 -39 -82
-86 -130 -167 -170 -93 -45 -179 -55 -477 -56 l-268 0 0 420 0 420 273 0 c185
-1 297 -5 349 -14z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
